import React, { useEffect, useState } from 'react';
import { Alert, CircularProgress, Box, Typography } from '@mui/joy';
import { Warning } from '@mui/icons-material';

export const BadConnectionWarning = ({ notifyBadNetwork }) => {
  const [badConnection, setBadConnection] = useState(false);

  const updateNetworkStatus = () => {
    notifyBadNetwork(navigator.onLine);
    setBadConnection(navigator.onLine);
  };

  //   sometimes, the load event does not trigger on some browsers, that is why manually calling updateNetworkStatus on initial mount
  useEffect(() => {
    updateNetworkStatus();
  }, []);

  useEffect(() => {
    window.addEventListener("load", updateNetworkStatus);
    window.addEventListener("online", updateNetworkStatus);
    window.addEventListener("offline", updateNetworkStatus);

    return () => {
        window.removeEventListener("load", updateNetworkStatus);
        window.removeEventListener("online", updateNetworkStatus);
        window.removeEventListener("offline", updateNetworkStatus);
    };
  }, [navigator.onLine]);

  return (
    <>
      {!badConnection ? <Alert
        variant="soft"
        color="danger"
        invertedColors
        startDecorator={
          <CircularProgress size="md" color="danger">
            <Warning />
          </CircularProgress>
        }
        sx={{ alignItems: 'flex-start', gap: '1rem', width: "50%" }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography level="title-md">Lost connection</Typography>
          <Typography level="body-md">
            Please verify your network connection and try again.
          </Typography>
        </Box>
      </Alert> : <Box sx={{visibility: "hidden", width: "50%"}}/>}
    </>
  );
};

export default BadConnectionWarning;
